import { useContext } from 'react'
import {
    /* Firebase */
    /* Components */
    /* Context */
    MainContext,
    /* Views */
    /* Custom Hooks */
    /* Service Classes */
    /* Utility Functions */
    /* Assets */
    galleryConfig,
    /* Icons */
    /* Configs */
    /* Types */
    /* Interfaces */
    /* DeveloperTools */
    debug,
    logInit,
    trace,
    m
} from '../../app-index'

/* Trace vars */
const run = 0
const file = 'NEW'
const msg = (copy, fileName = file) => m(copy, fileName)
/* END Trace vars */

import { Swiper, SwiperSlide } from '../../../node_modules/swiper/swiper-react.mjs'
import { Mousewheel, Pagination, FreeMode } from 'swiper/modules'
import ProjectEntry from './project-entry.component'

export default function ProjectList({ type, handleIndexChange, handleSwiperInit, handleTitleClick, activeEntryIndex }) {
    const {
        mainState: {
            professionalActive,
            personalActive,
            aboutActive,
            contactActive,
            isSubTablet
        }
    } = useContext(MainContext)

    const fadeClass = type === 'pro' ? 'pro-fade' : 'pers-fade'
    const entryClass = type === 'pro' ? 'professional-entry' : 'personal-entry'
    const galleryCategory = type === 'pro' ? galleryConfig.professional : galleryConfig.personal
    const galleryActive = type === 'pro' ? professionalActive : personalActive
    const isDisabled = aboutActive || contactActive

    return (
        <div className={`${fadeClass} ${galleryActive ? 'isActive' : ''} ${isDisabled ? 'isDisabled' : ''}`}>
            <Swiper
                onSwiper={handleSwiperInit}
                onActiveIndexChange={handleIndexChange}
                direction={'vertical'}
                resistanceRatio={0.1}
                touchRatio={0.5}
                freeMode={true}
                speed={500}
                // freeModeMomentum={true}
                slidesPerView={3}
                spaceBetween={isSubTablet ? 140 : 110}
                mousewheel={true}
                centeredSlides={true}
                slideToClickedSlide={true}
                pagination={{
                    clickable: true,
                }}
                modules={[Mousewheel, Pagination]}
                className={`mySwiper ${type}`}
            >
                {galleryCategory.map((entry, index) => {
                    // if (isSubTablet && entry.title === 'Solar Orbiter') return
                    // if (index > 2) return
                    return (
                        <SwiperSlide
                            key={entry.title}
                            onClick={handleTitleClick}
                        >
                            <ProjectEntry entry={entry} index={index} entryClass={entryClass} activeEntryIndex={activeEntryIndex} handleIndexChange={handleIndexChange} />
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    )
}