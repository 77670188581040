import { useContext } from 'react'
import {
    /* Firebase */
    /* Components */
    /* Context */
    MainContext,
    /* Views */
    /* Custom Hooks */
    /* Service Classes */
    DisplayService,
    /* Utility Functions */
    /* Assets */
    /* Icons */
    /* Configs */
    /* Types */
    /* Interfaces */
    /* DeveloperTools */
    debug,
    logInit,
    trace,
    m,
    SVGIcon,
} from '../../app-index'

/* Trace vars */
const run = 0
const file = 'Beta'
const msg = (copy, fileName = file) => m(copy, fileName)
/* END Trace vars */

export default function Beta() {
    const {
        mainState: {
            isBetaActive
        }
    } = useContext(MainContext)

    const closeModal = () => {
        DisplayService.closeBetaModal()
    }

    const githubIssueLink = (issueNumber, link) => {
        return (
            <a href={link} target='_blank'>{issueNumber}</a>
        )
    }

    return (
        <div className={`beta-wrapper ${isBetaActive ? '' : 'isHidden'}`} onClick={closeModal}>
            <button type='button' onClick={closeModal}><SVGIcon src='circleX' /></button>
            <h6>Welcome!</h6>
            <span style={{ textAlign: 'center' }}>Click modal to close, or follow links&nbsp;to&nbsp;issues.</span>
            <section className='mock-code'>
                <span className='known-issues'>Known Issues under active development:<span className='blink'>{String.fromCharCode(9646)}</span></span>
                <span className='date'>Beta Updated: 11/14/24 10:18am PST</span>
                <ul>
                    <li>{githubIssueLink('Project highlights not yet displayed - #2', 'https://github.com/users/ashthomasweb/projects/6/views/1?pane=issue&itemId=87049753&issue=ashthomasweb%7Cportfolio-v2%7C2')}</li>
                    <li>{githubIssueLink('Improve Swiper for trackpads with momentum scrolling - #5', 'https://github.com/ashthomasweb/portfolio-v2/issues/5')}</li>
                    <li>{githubIssueLink('Improve Safari support - #4', 'https://github.com/ashthomasweb/portfolio-v2/issues/4')}</li>
                    <li>{githubIssueLink('Improve entry transition quality - #1', 'https://github.com/users/ashthomasweb/projects/6/views/1?pane=issue&itemId=87049457&issue=ashthomasweb%7Cportfolio-v2%7C1')}</li>
                    <li>{githubIssueLink('Finalize Light Mode and enable - #6', 'https://github.com/ashthomasweb/portfolio-v2/issues/6')}</li>
                    <li>{githubIssueLink('Transition jitters - #1', 'https://github.com/ashthomasweb/portfolio-v2/issues/1')}</li>
                    <li className='strikeout'>✅ {githubIssueLink('Hex optimization - #3', 'https://github.com/users/ashthomasweb/projects/6/views/1?pane=issue&itemId=87050269&issue=ashthomasweb%7Cportfolio-v2%7C3')}</li>
                    <li className='strikeout'>✅ {githubIssueLink('Nav final polish - #7', 'https://github.com/ashthomasweb/portfolio-v2/issues/7')}</li>
                    <li className='strikeout'>✅ {githubIssueLink('Project gallery images - #8', 'https://github.com/ashthomasweb/portfolio-v2/issues/8')}</li>
                </ul>

            </section>
            <br />
            <span style={{ textAlign: 'center' }}>Thanks for visiting!<br />Please check back soon for updates.</span>
        </div>
    )
}

  
  
  
  
