import { useContext, useState, useEffect, useRef } from 'react'
import {
    /* Firebase */
    /* Components */
    SVGIcon,
    /* Context */
    MainContext,
    /* Views */
    /* Custom Hooks */
    /* Service Classes */
    DisplayService,
    /* Utility Functions */
    /* Assets */
    /* Icons */
    /* Configs */
    /* Types */
    /* Interfaces */
    /* DeveloperTools */
    debug,
    logInit,
    trace,
    m,
} from '../../app-index'

import ProjectList from './project-list.component copy'

/* Trace vars */
const run = 0
const file = 'Accordion'
const msg = (copy, fileName = file) => m(copy, fileName)
/* END Trace vars */

export default function Accordion() {
    const {
        mainState: {
            sidebarActive,
            professionalActive,
            personalActive,
            aboutActive,
            contactActive,
            activeEntryFlag,
            projectEntryIsActive
        }
    } = useContext(MainContext)

    const [activeEntryIndex, setActiveEntryIndex] = useState(null)
    const [centeredProIndex, setCenteredProIndex] = useState(null)
    const [centeredPersIndex, setCenteredPersIndex] = useState(null)

    const proMainSwiperRef = useRef(null)
    const persMainSwiperRef = useRef(null)
    const sidebarRef = useRef(null)

    const [displayEmailAlert, setDisplayEmailAlert] = useState(false)

    useEffect(() => {
        const swiperArray = [proMainSwiperRef.current, persMainSwiperRef.current]

        const eventArray = ['allowSlideNext', 'allowSlidePrev', 'allowTouchMove']
        if (projectEntryIsActive) {
            swiperArray.forEach(swiper => {
                eventArray.forEach(event => {
                    swiper[event] = false
                })
            })
        } else {
            swiperArray.forEach(swiper => {
                eventArray.forEach(event => {
                    swiper[event] = true
                })
            })
        }
    }, [projectEntryIsActive])

    useEffect(() => {
        // trace(run) && log(...msg('UE activeEntryIndex'), activeEntryIndex)
        DisplayService.setEntryIsActive(activeEntryIndex !== null)
    }, [activeEntryIndex])

    useEffect(() => {
        // trace(run) && log(...msg('UE activeEntryFlag Switch'))
        setActiveEntryIndex(null)
    }, [activeEntryFlag])

    useEffect(() => {
        // trace(run) && log(...msg('UE pro/pers active switch'))
        setActiveEntryIndex(null)
    }, [professionalActive, personalActive])

    const handleTitleClick = ({ target }) => {
        // trace(run) && log(...msg('handleTitleClick'))

        if (target.tagName.match(/H3|SPAN/)) {
            // Set clicked element as activeEntry, or toggle off activeEntry is user clicks the currently activeEntry
            setActiveEntryIndex(activeEntryIndex === +target.dataset.entryindex ? null : +target.dataset.entryindex)
        }

    }

    const handleChevronClick = () => {
        // trace(run) && log(...msg('handleChevronClick'))
        if (professionalActive) {
            setActiveEntryIndex(activeEntryIndex === centeredProIndex ? null : centeredProIndex)
        } else if (personalActive) {
            setActiveEntryIndex(activeEntryIndex === centeredPersIndex ? null : centeredPersIndex)
        }
    }

    const handleIndexChange = (swiper) => {
        // trace(run) && log(...msg('handleIndexChange'))
        if (swiper.hostEl.className.includes('pro')) {
            setCenteredProIndex(swiper.activeIndex)
        } else if (swiper.hostEl.className.includes('pers')) {
            setCenteredPersIndex(swiper.activeIndex)
        }
    }

    const handleSwiperInit = (swiper) => {
        trace(run) && log(...msg('handleSwiperInit'))
        // dir(swiper)
        swiper.hostEl.className.includes('pro') ? (proMainSwiperRef.current = swiper) : (persMainSwiperRef.current = swiper)
        setCenteredPersIndex(swiper.activeIndex)
        setCenteredProIndex(swiper.activeIndex)
    }

    // TODO: Initial solution for solving the console warning about non-passive event listener within Swiper
    // Need to handle up/down wheel, and which swiper instance is currently displayed
    // Potential for limiting overscroll momentum scrolling on trackpads
    // useEffect(() => {
    //     const handleWheelEvent = () => {
    //         proMainSwiperRef.current.slideNext(300)
    //     }

    //     window.addEventListener('wheel', handleWheelEvent, { passive: true })
    //     return () => {
    //         window.removeEventListener('wheel', handleWheelEvent, { passive: true })
    //     }
    // }, [])

    const handleEmailCopy = () => {
        navigator.clipboard.writeText('ashthomascode@gmail.com')
        setDisplayEmailAlert(true)
        setTimeout(() => {
            setDisplayEmailAlert(false)
        }, 2500)
    }

    return (
        <div ref={sidebarRef} className={`sidebar ${sidebarActive ? 'isOpen' : ''} ${aboutActive ? 'aboutFormat' : ''} ${contactActive ? 'contactFormat' : ''}`}>

            <section className={`about-container about-fade ${aboutActive ? 'isActive' : ''}`}>
                <p>My Name is Ash Thomas. Whether it’s a design pattern, a piano, or a mountain single track - engaging
                    with the systems our world is built on brings me happiness and satisfaction.</p>
            </section>

            <section className={`contact-container contact-fade ${contactActive ? 'isActive' : ''}`}>
                <span
                    className={`${sidebarActive ? 'copy-email' : ''} ${displayEmailAlert ? 'email-alert-active' : ''}`}
                    onClick={handleEmailCopy}
                >
                    <SVGIcon src='copy' fill='white' scale={0.8} additionalClass={`copy-icon `} />
                    {
                        sidebarActive
                            ? <span className={`copied-alert ${displayEmailAlert ? 'isDisplayed' : ''}`}>Email Copied!</span>
                            : null
                    }
                    <span className={`email ${!displayEmailAlert ? 'isDisplayed' : ''}`}>ashthomascode@gmail.com</span>
                </span>

                <a href='https://github.com/ashthomasweb' target='_blank' ><span className='github-profile'><SVGIcon src='githubSquare' fill='white' />github.com/ashthomasweb</span></ a>

            </section>

            <section className={`accordion-container project-fade ${professionalActive || personalActive ? 'isActive' : 'notActive'}`}>

                <div className={`display-pane`} >
                    <div className={`scroll-shadow-upper ${activeEntryIndex !== null ? 'isShrunk' : ''}`} ></div>

                    <div className={`focus--center ${sidebarActive && (professionalActive || personalActive) ? 'isBlurred' : ''} ${activeEntryIndex !== null ? 'isExpanded' : ''}`}></div>

                    <div
                        className={`chevron-container ${activeEntryIndex !== null ? 'isOpen' : ''}`}
                        onMouseUp={handleChevronClick}
                    >
                        <SVGIcon src='circlePlus' fill={'#448ec8'} />
                    </div>

                    <ProjectList type={'pro'} handleIndexChange={handleIndexChange} handleSwiperInit={handleSwiperInit} handleTitleClick={handleTitleClick} activeEntryIndex={activeEntryIndex} />
                    <ProjectList type={'pers'} handleIndexChange={handleIndexChange} handleSwiperInit={handleSwiperInit} handleTitleClick={handleTitleClick} activeEntryIndex={activeEntryIndex} />

                    <div className={`scroll-shadow-lower ${activeEntryIndex !== null ? 'isShrunk' : ''}`} ></div>
                </div>

            </section>
        </div>
    )
}
