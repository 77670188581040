import { useContext, useEffect, useMemo, useRef } from 'react'
import {
    /* Firebase */
    useFirebase,
    /* Context */
    MainContext,
    /* Components */
    UserAuth,
    Name,
    SVGHex,
    PrimaryNav,
    Accordion,
    /* Views */
    ContentView,
    /* Custom Hooks */
    logComponentInit,
    /* Service Classes */
    ThemeService,
    /* Utility Functions */
    /* Assets */
    /* Icons */
    /* Configs */
    /* Types */
    /* Interfaces */
    /* DeveloperTools */
    debug,
    logInit,
    trace,
    m,
    DisplayService,
    Beta,
} from '../../app-index'

/* Trace vars */
const run = 0
const file = 'AppView'
const msg = (copy, fileName = file) => m(copy, fileName)
/* END Trace vars */

export default function AppView() {
    logInit && logComponentInit(file)

    const {
        mainState: {
            userObj,
            theme,
            hexAnimationFall,
            hexAnimationFloat,
            hexAnimationNone,
            professionalActive,
            personalActive,
            aboutActive,
            contactActive,
        }
    } = useContext(MainContext)

    const appViewRef = useRef(null)

    useEffect(() => {
        ThemeService.appViewRef = appViewRef
    })

    useEffect(() => {

        if (window.innerWidth < 768) {
            DisplayService.setHexAnimation('none')
            DisplayService.setIsSubTablet(true)
        } else {
            DisplayService.setIsSubTablet(false)
        }
    }, [])

    useEffect(() => {
        function handleHexAnimationToggle() {
            if (window.innerWidth < 768) {
                DisplayService.setHexAnimation('none')
                DisplayService.setIsSubTablet(true)
            } else {
                DisplayService.setIsSubTablet(false)
            }
        }

        window.addEventListener('resize', handleHexAnimationToggle)
        return () => {
            window.removeEventListener('resize', handleHexAnimationToggle)
        }
    }, [])

    const hexArrayRow1 = Array(17).fill(null)
    const hexArrayRows = Array(17).fill(null)

    return (
        <div className='app-view-container' ref={appViewRef} data-style={theme}>
            {
                <Beta />
            }
            {
                userObj !== null || !useFirebase
                    ? <>
                        <ContentView >
                            <Name />

                            {
                                useMemo(() => (
                                    <div className={`scene-tilt ${professionalActive || personalActive || aboutActive || contactActive ? 'isMuted' : ''}`}>
                                        <div className="tilted-plane">
                                            {
                                                hexArrayRows.map((row, index) => {
                                                    return hexArrayRow1.map((entry, hexIndex) => {
                                                        // Top left corner
                                                        if (index === 0 && hexIndex >= 0 && hexIndex <= 7) return
                                                        if (index === 1 && hexIndex >= 0 && hexIndex <= 5) return
                                                        if (index === 2 && hexIndex >= 0 && hexIndex <= 3) return
                                                        if (index === 3 && hexIndex >= 0 && hexIndex <= 1) return

                                                        // Bottom left corner
                                                        if (index === 0 && hexIndex >= 9 && hexIndex <= 16) return
                                                        if (index === 1 && hexIndex >= 11 && hexIndex <= 16) return
                                                        if (index === 2 && hexIndex >= 13 && hexIndex <= 16) return
                                                        if (index === 3 && hexIndex >= 15 && hexIndex <= 16) return

                                                        // Top right corner
                                                        if (index === 16 && hexIndex >= 0 && hexIndex <= 6) return
                                                        if (index === 15 && hexIndex >= 0 && hexIndex <= 4) return
                                                        if (index === 14 && hexIndex >= 0 && hexIndex <= 2) return
                                                        if (index === 13 && hexIndex >= 0 && hexIndex <= 0) return

                                                        // Bottom right corner
                                                        if (index === 16 && hexIndex >= 10 && hexIndex <= 16) return
                                                        if (index === 15 && hexIndex >= 12 && hexIndex <= 16) return
                                                        if (index === 14 && hexIndex >= 14 && hexIndex <= 16) return
                                                        if (index === 13 && hexIndex >= 16 && hexIndex <= 16) return

                                                        if (hexIndex % 2 === 0) {
                                                            return <SVGHex key={Math.ceil(Math.random() * 10e15)} fill='#add8f6' isHex offsetX={index * 18 + 9} offsetY={hexIndex * 16} />
                                                        } else {
                                                            return <SVGHex key={Math.ceil(Math.random() * 10e15)} fill='#add8f6' isHex offsetX={index * 18} offsetY={hexIndex * 16} />
                                                        }
                                                    })
                                                })
                                            }
                                        </div>
                                    </div>
                                ), [hexAnimationNone, hexAnimationFall, hexAnimationFloat, professionalActive, personalActive, aboutActive, contactActive])
                            }

                            <PrimaryNav />
                            <Accordion />

                        </ContentView>
                    </>
                    : null
            }
        </div>
    )
}
