import { useContext, useState } from 'react'
import {
    /* Firebase */
    /* Components */
    SVGIcon,
    SVGHex,
    /* Context */
    MainContext,
    /* Views */
    /* Custom Hooks */
    /* Service Classes */
    ThemeService,
    /* Utility Functions */
    /* Assets */
    /* Icons */
    /* Configs */
    /* Types */
    /* Interfaces */
    /* DeveloperTools */
    debug,
    logInit,
    trace,
    m,
    DisplayService
} from '../../app-index'

import hexArrow from '../../_assets/icons/hexArrow.png'

/* Trace vars */
const run = 0
const file = 'HexToggle'
const msg = (copy, fileName = file) => m(copy, fileName)
/* END Trace vars */

export default function HexToggle() {
    logInit && logComponentInit(file)

    const {
        mainState: {
            hexAnimationFloat,
            hexAnimationFall,
            hexAnimationNone
        }
    } = useContext(MainContext)

    const handleHexToggle = ({ target }) => {
        DisplayService.setHexAnimation(target.dataset.animation)
    }

    const hexArrayRow1 = Array(5).fill(null)
    const hexArrayRows = Array(7).fill(null)

    return (
        <div className='hex-toggle-container'>

            <label className="switch">
                <span className="slider">
                    <div className='hex-backing'>
                        {
                            hexArrayRows.map((row, index) => {
                                return hexArrayRow1.map((entry, hexIndex) => {
                                    if (hexIndex % 2 === 0) {
                                        return <SVGHex key={Math.ceil(Math.random() * 10e15)} src='hexagon' fill='#161616' background={'#161616'} isHex offsetX={index * 18 + 9} offsetY={hexIndex * 16} />
                                    } else {
                                        return <SVGHex key={Math.ceil(Math.random() * 10e15)} src='hexagon' fill='#161616' background={'#161616'} isHex offsetX={index * 18} offsetY={hexIndex * 16} />
                                    }
                                })
                            })
                        }
                    </div>
                    <button type='button' data-animation='none' onClick={handleHexToggle}>
                        <SVGIcon src={'ban'} fill={'#dedede'} />
                    </button>
                    <button type='button' data-animation='float' onClick={handleHexToggle}>
                        <SVGIcon src={'angleUp'} fill={'#dedede'} />
                    </button>
                    <button type='button' data-animation='fall' onClick={handleHexToggle}>
                        <SVGIcon src={'anglesDown'} fill={'#dedede'} />
                    </button>

                    <div
                        className={
                            `icon-container ${hexAnimationNone
                                ? 'isNone'
                                : hexAnimationFloat
                                    ? 'isFloat'
                                    : 'isFall'
                            }`
                        }
                    >
                        {
                            hexAnimationNone
                                ? <SVGIcon src={'ban'} fill={'#72aec2'} />
                                : null
                        }
                        {
                            hexAnimationFloat
                                ? <SVGIcon src={'angleUp'} fill={'#72aec2'} />
                                : null
                        }
                        {
                            hexAnimationFall
                                ? <SVGIcon src={'anglesDown'} fill={'#72aec2'} />
                                : null
                        }
                    </div>
                </span>
            </label>
        </div>
    )
}
