import { useContext, useEffect, useMemo, useState } from 'react'
import {
    /* Firebase */
    /* Components */
    NeuButton,
    ThemeToggle,
    /* Context */
    MainContext,
    /* Views */
    /* Custom Hooks */
    /* Service Classes */
    /* Utility Functions */
    /* Assets */
    /* Icons */
    /* Configs */
    /* Types */
    /* Interfaces */
    /* DeveloperTools */
    debug,
    logInit,
    trace,
    m,
    DisplayService,
    HexToggle,
    SVGIcon
} from '../../app-index'

/* Trace vars */
const run = 0
const file = 'PrimaryNav'
const msg = (copy, fileName = file) => m(copy, fileName)
/* END Trace vars */

export default function PrimaryNav() {
    const {
        mainState: {
            sidebarActive,
            projectEntryIsActive,
            activeEntryFlag,
            isSubTablet,
            professionalActive,
            personalActive,
            aboutActive
        }
    } = useContext(MainContext)

    const [primaryNavDisplay, setPrimaryNavDisplay] = useState({
        professional: false,
        personal: false,
        about: false,
        contact: false
    })

    const toggleNav = ({ target }) => {
        trace(run) && log(...msg('toggleNav'))

        if (sidebarActive && projectEntryIsActive && primaryNavDisplay[target.dataset.nav]) {
            DisplayService.toggleFlagForActiveEntry(activeEntryFlag)
            return
        }

        const newPrimaryNavDisplay = { ...primaryNavDisplay }
        const selectedMenu = target.dataset.nav
        const currentMenuState = primaryNavDisplay[selectedMenu]
        for (const key in primaryNavDisplay) {
            newPrimaryNavDisplay[key] = false
        }
        newPrimaryNavDisplay[selectedMenu] = !currentMenuState
        setPrimaryNavDisplay(newPrimaryNavDisplay)
    }

    // TODO: Investigate why this function, when called synchronously, is interrupting final paint of certain elements styles in the selected NeuButton
    useEffect(() => { 
        setTimeout(() => {
            DisplayService.setSidebarState(primaryNavDisplay)
        }, 200)
    }, [primaryNavDisplay])

    const handleReturnToNav = () => {
        const newPrimaryNavDisplay = { ...primaryNavDisplay }
        for (const key in primaryNavDisplay) {
            newPrimaryNavDisplay[key] = false
        }
        setPrimaryNavDisplay(newPrimaryNavDisplay)
        DisplayService.setSidebarState(newPrimaryNavDisplay)
    }

    const isSafariCheck = () => {
        return CSS.supports('-webkit-backdrop-filter', 'blur(0px)') && !CSS.supports('backdrop-filter', 'blur(0px)')
    }

    return (
        <div className={`primary-nav ${isSubTablet && (professionalActive || personalActive || aboutActive) ? 'isInactive' : ''}`}>
            <div className='neu-elements'>
                <NeuButton
                    copy={'About'}
                    nav={'about'}
                    click={toggleNav}
                    isActive={primaryNavDisplay}
                    additionalClass={`${isSubTablet ? 'isMobile' : ''} ${isSubTablet && (professionalActive || personalActive || aboutActive) ? 'isHidden' : ''} `}
                />
                <NeuButton
                    copy={'Professional'}
                    nav={'professional'}
                    click={toggleNav}
                    isActive={primaryNavDisplay}
                    additionalClass={`${isSubTablet ? 'isMobile' : ''} ${isSubTablet && (personalActive || aboutActive) ? 'isHidden' : ''} ${isSubTablet && primaryNavDisplay.professional ? 'isHidden' : ''}`}
                />
                <NeuButton
                    copy={'Personal'}
                    nav={'personal'}
                    click={toggleNav}
                    isActive={primaryNavDisplay}
                    additionalClass={`${isSubTablet ? 'isMobile' : ''} ${isSubTablet && (professionalActive || aboutActive) ? 'isHidden' : ''} ${isSubTablet && primaryNavDisplay.personal ? 'isHidden' : ''}`}
                />
                <NeuButton
                    copy={'Contact'}
                    nav={'contact'}
                    click={toggleNav}
                    isActive={primaryNavDisplay}
                    additionalClass={`${isSubTablet ? 'isMobile' : ''} ${isSubTablet && (professionalActive || personalActive || aboutActive) ? 'isHidden' : ''}`}
                />
                {
                    isSubTablet
                        ? (
                            <NeuButton nav={'home'} click={handleReturnToNav} additionalClass={`isHome ${professionalActive || personalActive || aboutActive ? 'isDisplayed-home' : 'isHidden-home'}`} >
                                <SVGIcon src='house' fill='white' />
                            </NeuButton>
                        )
                        : null
                }
            </div>

            {
                !isSubTablet && !isSafariCheck()
                    ? <HexToggle />
                    : null
            }

            {/* <ThemeToggle additionalClass={`${isSubTablet && (professionalActive || personalActive || aboutActive) ? 'hide' : ''}`} /> */}
        </div>
    )
}