import { useContext } from 'react'
import {
    /* Firebase */
    /* Components */
    /* Context */
    MainContext,
    /* Views */
    /* Custom Hooks */
    /* Service Classes */
    /* Utility Functions */
    /* Assets */
    /* Icons */
    /* Configs */
    /* Types */
    /* Interfaces */
    /* DeveloperTools */
    debug,
    logInit,
    trace,
    m
} from '../../app-index'

/* Trace vars */
const run = false
const file = 'NEW'
const msg = (copy, fileName = file) => m(copy, fileName)
/* END Trace vars */
import ProjectGallery from './project-gallery.component'

export default function ProjectEntry({ entry, index, entryClass, activeEntryIndex, handleIndexChange }) {
    const {
        mainState: {
            sidebarActive,
            isSubTablet
        }
    } = useContext(MainContext)

    const isActive = activeEntryIndex === index
    const isInactive = activeEntryIndex !== index

    return (
        <div
            // className={`entry-container ${entryClass} ${isActive ? `isActive` : ``} ${activeEntryIndex !== null && activeEntryIndex !== index ? index < activeEntryIndex ? 'pushUp' : 'pushDown' : ''} ${!isSubTablet ? 'isDesktopVersion' : ''}`}
            className={`entry-container ${entryClass} ${isActive ? `isActive` : ``} ${activeEntryIndex !== null && !isActive && activeEntryIndex - 1 === index ? 'pushUp' : activeEntryIndex !== null && !isActive && activeEntryIndex + 1 === index ? 'pushDown' : ''} ${!isSubTablet ? 'isDesktopVersion' : ''}`}
            // className={`entry-container ${entryClass} ${isActive ? `isActive` : ``}  ${!isSubTablet ? 'isDesktopVersion' : ''}`}
            
            data-entrytag={entry.tag}
            data-entryindex={index}
        >
            <div
                className={`content-wrapper ${sidebarActive ? 'isOpen' : ''}`}
                data-entrytag={entry.tag}
                data-entryindex={index}
                data-openentry={true}
            >
                <h3
                    data-entrytag={entry.tag}
                    data-entryindex={index}
                    data-openentry={true}
                >
                    {entry.title}
                </h3>
                <div className='inner-content-wrapper'>
                    <span
                        className={`inactive-description ${isInactive ? 'isDisplayed' : 'isHidden'}`}
                        data-entrytag={entry.tag}
                        data-entryindex={index}
                        data-openentry={true}
                    >
                        {entry.shortDescription}
                    </span>
                    <div className='expanded-content'>
                        <div className='stack-list'>
                            {
                                entry.stackList.map((item, index) => {
                                    return (<span key={Math.ceil(Math.random() * 10e15)}>
                                        <span>{item}</span>
                                        {index < entry.stackList.length - 1 ? `\u25CF` : null}
                                    </span>
                                    )
                                })
                            }
                        </div>
                        <div className='role-link-section'>
                            <span className='role'>{entry.role}</span>
                            {
                                entry.liveLink
                                    ? <span className='live-link'><a href={entry.liveLink} target='_blank'>Live Site</a></span>
                                    : null
                            }
                        </div>
                        <hr />
                        <ProjectGallery entry={entry} handleIndexChange={handleIndexChange} isActive={isActive} />
                        <span className='extended-description'>{entry.description}</span>
                    </div>
                </div>

            </div>
            <section className={`entry-footer ${isActive ? 'isActive' : ''}`}>
                <hr />
                {entry.status ? (<span className='status'><em>{entry.status}</em></span>) : null}
                {entry.gitLink ? (<span className='git-link'><a href={entry.gitLink} target='_blank'>View on Github</a></span>) : null}
            </section>
        </div>
    )
}