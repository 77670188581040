import {
    /* Firebase */
    /* Components */
    /* Context */
    /* Views */
    /* Custom Hooks */
    /* Service Classes */
    /* Utility Functions */
    /* Assets */
    /* Icons */
    /* Configs */
    /* Types */
    /* Interfaces */
    /* DeveloperTools */
    debug,
    logInit,
    trace,
    m
} from '../../app-index'

/* Trace vars */
const run = 0
const file = 'DisplayService'
const msg = (copy, fileName = file) => m(copy, fileName)
/* END Trace vars */

class displayService {
    mainDispatch = null

    constructor() {
        /* Log class initialization */
        logInit && log(...msg('Init'))
    }

    setLocalDispatch(dispatch) {
        this.mainDispatch = dispatch
    }

    setEntryIsActive(input) {
        // trace(run) && log(...msg('setEntryIsActive'), input)
        
        const payload = {
            projectEntryIsActive: input
        }
        this.mainDispatch({ payload })
    }

    toggleFlagForActiveEntry(input) {
        const payload = {
            activeEntryFlag: !input
        }
        this.mainDispatch({ payload })
    }

    setSidebarState(input) {
        // trace(run) && log(...msg('setSidebarState'))
        // trace(run) && log(input, selectedMenu)

        const payload = {
            sidebarActive: Object.values(input).includes(true),
            professionalActive: input.professional,
            personalActive: input.personal,
            aboutActive: input.about,
            contactActive: input.contact,
        }

        this.mainDispatch({ payload })
    }

    setHexAnimation(animName) {
        const payload = {
            hexAnimationFloat: animName === 'float',
            hexAnimationFall: animName === 'fall',
            hexAnimationNone: animName === 'none',
        }
        this.mainDispatch({ payload })
    }

    setIsSubTablet(input) {
        const payload = {
            isSubTablet: input
        }
        this.mainDispatch({ payload })
    }

    closeBetaModal() {
        const payload = {
            isBetaActive: false
        }
        this.mainDispatch({ payload })
    }

}

export default displayService
